"use client";
import { getPortfolioItems } from "@/data/portfolio";
import { cn } from "@/utils/cn";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FC, useRef, useEffect } from "react";
import { Card } from "../card";
import { DiscoverCard } from "../discover-card";
import { SectionTitle } from "../section-title";
import { useAnimate } from "@/store/animate";
import Link from "next/link";
import { useLocale, useTranslations } from "next-intl";

gsap.registerPlugin(ScrollTrigger);

const PortfolioPreviewContainer: FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const animRef = useRef<any>();
  const cardRef = useRef<any>();
  const { isAnimating } = useAnimate();
  const t = useTranslations("Home");
  const locale = useLocale();
  const portfolioItems = getPortfolioItems(locale);

  const updateAnimation = () => {
    if (!scrollableRef.current || !wrapperRef.current) return;
    const getScrollAmount = () =>
      scrollableRef.current ? -(scrollableRef.current?.scrollWidth! - wrapperRef.current?.clientWidth!) : 0;

    animRef.current = gsap.to(scrollableRef.current, {
      x: getScrollAmount(),
      duration: 3,
      ease: "none",
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "center center+=80vh",
        end: () =>
          scrollableRef.current ? `+=${scrollableRef.current?.scrollWidth! - wrapperRef.current?.clientWidth!}` : 0,
        pin: ".first",
        scrub: 1,
        invalidateOnRefresh: true,
        markers: false,
      },
    });

    // Entrance animation for cards
    cardRef.current = gsap.utils.toArray(".portfolio-preview-card, .discover-card").map((card, index) => {
      return gsap.fromTo(
        card as Element,
        { opacity: 0, x: "50vw" },
        {
          opacity: 1,
          x: 0,
          delay: index * 0.1, // Reduce the delay for smoother appearance
          duration: 1.2, // Increase duration slightly for a smoother transition
          ease: "power2.out",
          scrollTrigger: {
            trigger: card as Element,
            start: "top 80%",
          },
        }
      );
    });
  };

  useEffect(() => {
    updateAnimation();

    return () => {
      animRef.current?.kill();
      cardRef.current?.forEach((card: any) => card.kill());
    };
  }, []);

  return (
    <div
      className={cn("flex flex-col justify-center  lg:mt-[20vh]  opacity-0", {
        "opacity-100": !isAnimating,
      })}
    >
      <div className="flex flex-col items-start pl-5 md:pl-9 mt-5 max-w-full">
        <SectionTitle title="portfolio" heading="ourWorks" />
      </div>
      <div ref={wrapperRef} className="overflow-hidden relative">
        <div
          ref={scrollableRef}
          className={cn("flex w-fit gap-2.5 mt-7 wrap-nowrap px-2.5 pt-2.5")}
          style={{ willChange: "transform" }}
        >
          {Object.entries(portfolioItems)
            .slice(0, 6)
            .map(([key, portfolio]) => (
              <Link key={key} href={`/${locale}/portfolio/${key}`}>
                <Card
                  imageSrc={portfolio.imageSrc}
                  imageOverlaySrc={portfolio.imageOverlaySrc}
                  tags={portfolio.tags}
                  title={portfolio.title}
                  ctaText={portfolio.ctaText}
                  projectId={key}
                  className="portfolio-preview-card w-80 md:w-96 aspect-[0.81] will-change-[transform,_opacity]"
                />
              </Link>
            ))}
          <DiscoverCard
            href={`/${locale}/portfolio`}
            text={t("discoverProjects")}
            className="discover-card w-80 md:w-96 will-change-[transform,_opacity] text-5xl xxl:text-8xl"
          />
        </div>
      </div>
    </div>
  );
};

export default PortfolioPreviewContainer;
