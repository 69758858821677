"use client";
import React, { useEffect, useRef, useState } from "react";
import { cn } from "@/utils/cn";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { Button } from "@/components/button";
import { throttle } from "@/utils/throttle";
import { useBlur } from "@/store/blur";
import { useLenis } from "@studio-freight/react-lenis";
import { useAnimate } from "@/store/animate";
import { useTranslations } from "next-intl";
import { useLocale } from "next-intl";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

interface LottieScrollAnimationProps {
  animationData: object;
  className?: string;
}

const framesPerStep = [0, (688 / 4) * 1, (688 / 4) * 2, (688 / 4) * 3, 688];
const LottieScrollAnimation: React.FC<LottieScrollAnimationProps> = ({ animationData, className }) => {
  const lenis = useLenis();
  const { setIsBlur } = useBlur();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const animationWrapperRef = useRef<HTMLDivElement>(null);
  const animRef = useRef<any>();
  const scrollPercentage = useRef<number>(0);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentFrame, setCurrentFrame] = useState(0);
  const scrollTriggerInstance = useRef<any>();
  const wordsContainerRef = useRef<HTMLDivElement>(null);
  const { isAnimating } = useAnimate();
  const t = useTranslations("Home");
  const locale = useLocale();

  const handleScroll = throttle(() => {
    setShowBackToTop(scrollPercentage.current > 0.55);
  }, 500);

  useEffect(() => {
    if (!animationWrapperRef.current) return;

    const initAnimation = async () => {
      if (!animationWrapperRef.current) return;

      const lottie = await import("lottie-web/build/player/lottie_light");

      animRef.current = lottie.default.loadAnimation({
        container: animationWrapperRef.current as Element,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: animationData,
      });

      scrollTriggerInstance.current = ScrollTrigger.create({
        trigger: wrapperRef.current,
        start: "top top",
        end: "+=4000",
        pin: true,
        scrub: 1,
        onUpdate: (self) => {
          requestAnimationFrame(() => {
            scrollPercentage.current = self.progress;
            const frame = Math.round(animRef.current.totalFrames * self.progress);
            animRef.current.goToAndStop(frame, true);
            setCurrentFrame(frame);
            updateSlider();
            handleScroll();
            updateWordsPosition(frame);
          });
        },
      });
    };

    initAnimation();

    return () => {
      animRef.current?.destroy();
      scrollTriggerInstance.current?.kill();
    };
  }, [animationData]);

  const scrollTop = () => {
    lenis?.stop();
    setIsBlur(true);
    setTimeout(() => {
      gsap.to(window, {
        duration: 0.15,
        scrollTo: 0,
        onComplete: () => {
          lenis?.start();
          setIsBlur(false);
          setShowBackToTop(false);
        },
      });
    }, 150);
  };

  const updateSlider = () => {
    const totalFrames = animRef.current.totalFrames;
    const currentFrame = animRef.current.currentFrame;

    let step = 0;
    for (let i = 0; i < framesPerStep.length - 1; i++) {
      if (currentFrame >= framesPerStep[i] && currentFrame < framesPerStep[i + 1]) {
        step = i;
        break;
      }
    }
    if (currentFrame >= framesPerStep[framesPerStep.length - 1]) {
      step = framesPerStep.length - 1;
    }

    setCurrentStep(step);

    const stepStartFrame = framesPerStep[step];
    const stepEndFrame = step < framesPerStep.length - 1 ? framesPerStep[step + 1] : totalFrames;
    const stepProgress = (currentFrame - stepStartFrame) / (stepEndFrame - stepStartFrame);
    const progressElements = document.querySelectorAll(`.slider-step .inner-progress`);
    if (progressElements) {
      progressElements.forEach((element, index) => {
        if (index === step) {
          (element as HTMLElement).style.width = `${stepProgress * 100}%`;
        } else if (index < step) {
          (element as HTMLElement).style.width = `100%`;
        } else {
          (element as HTMLElement).style.width = `0%`;
        }
      });
    }
  };

  const updateWordsPosition = (frame: number) => {
    const container = wordsContainerRef.current;
    if (!container) return;

    const wordCount = container.children.length;
    const wordWidth = container.clientWidth / wordCount;
    const totalWidth = wordWidth * (wordCount - 1);

    const step = framesPerStep.findIndex((stepFrame, index) => frame >= stepFrame && frame < framesPerStep[index + 1]);

    if (frame >= framesPerStep[framesPerStep.length - 1]) {
      gsap.to(container, {
        x: -totalWidth,
        ease: "power2.out",
        duration: 0.5,
      });
    } else {
      const offset = -(wordWidth * step);
      gsap.to(container, {
        x: offset,
        ease: "power2.out",
        duration: 0.5,
      });
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={cn(
        " values flex flex-col justify-center items-center w-full p-2.5 min-h-screen max-h-screen overflow-hidden relative opacity-0",
        { "opacity-100": !isAnimating },
        className
      )}
      style={{ willChange: "transform" }}
    >
      <div className="flex justify-center flex-1 items-center w-full rounded-xl overflow-hidden relative">
        <div className={cn("h-full absolute")} ref={animationWrapperRef} style={{ willChange: "transform, opacity" }} />
        <div className="absolute w-full px-3 top-3">
          <div className="slider flex justify-center items-center relative w-full">
            {framesPerStep.slice(0, -1).map((_, index) => (
              <div
                key={index}
                className={cn("slider-step relative mx-1 flex-1 h-1 w-0 bg-[#d7d56f] rounded-full transition-all", {
                  "bg-[#d7d56f] w-auto": index === currentStep,
                  "bg-black w-auto": index < currentStep,
                })}
              >
                <div
                  className="inner-progress absolute top-0 left-0 w-0 h-full bg-black rounded-full"
                  style={{ willChange: "width" }}
                />
              </div>
            ))}
          </div>
        </div>

        {locale === "en" && (
          <>
            <div className="absolute bottom-0 lg:bottom-[10vh] xl:bottom-0 left-0 right-0 h-[40vh] sm:h-1/3 md:h-[30vh] lg:h-1/4 top-50 bg-[#fff96b] z-0"></div>
            <div className="absolute bottom-64 sm:bottom-60 md:bottom-52 lg:bottom-[10vh] xl:bottom-0 w-full lg:w-4/6 bg-[#fff96b] lg:h-44 overflow-hidden text-dark-gray z-10">
              <div
                className="flex justify-center items-center bg-[#fff96b] py-5 sm:py-8 md:py-2 lg:py-8 text-3xl sm:text-4xl md:text-5xl font-medium "
                ref={wordsContainerRef}
                style={{ width: "400%" }}
              >
                <h3 className="flex-none w-1/4 text-center">Reliability</h3>
                <h3 className="flex-none w-1/4 text-center">Growth</h3>
                <h3 className="flex-none w-1/4 text-center">Vision</h3>
                <h3 className="flex-none w-1/4 text-center">Connection</h3>
              </div>
            </div>
          </>
        )}

        <div className="bg-[#fff96b] absolute -z-10 top-0 left-0 h-full w-full" />
      </div>
      <Button
        className={cn("absolute bottom-7 right-5 md:bottom-11 md:right-10 transition-opacity", {
          "opacity-0": !showBackToTop,
          "opacity-100": showBackToTop,
        })}
        size="small"
        onClick={scrollTop}
      >
        {t("backToTop")}
      </Button>
    </div>
  );
};

export default LottieScrollAnimation;
