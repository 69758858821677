"use client";
import { Fragment } from "react";
import { SectionTitle } from "./section-title";
import { useAnimate } from "@/store/animate";
import { cn } from "@/utils/cn";
import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";
import TextAnimation from "./animations/text-animation";

const services = [
  {
    title: "design",
    services: ["corporateIdentity", "visualIdentity", "webApp", "uiUxDesign", "socialMediaContent", "illustration"],
  },
  {
    title: "development",
    services: ["appWebAppPWA", "managementSoftware", "wordpressWebflowFramer", "ecommerce", "frontend", "backend"],
  },
  {
    title: "marketing",
    services: [
      "strategy",
      "copywriting",
      "seoCro",
      "googleSocialAdvertising",
      "socialMediaManagement",
      "emailMarketing",
    ],
  },
];

const Services = () => {
  const { isAnimating } = useAnimate();
  const t = useTranslations("Home");

  return (
    <section
      className={cn("flex flex-col items-start my-32 max-md:mt-16 w-full px-6 md:px-11 opacity-0", {
        "opacity-100": !isAnimating,
      })}
    >
      <SectionTitle title="services" heading="whatWeDo" />
      <div className="self-stretch mt-10 md:mt-16 w-full">
        <div className="flex flex-wrap justify-between gap-8 md:gap-5">
          {services.map((service) => {
            const titleKey = `${service.title}`;

            return (
              <div key={titleKey} className="flex flex-col w-full sm:w-[calc(50%-1rem)] xl:w-[calc(33.333%-1rem)]">
                <div className="flex flex-col sm:flex-row gap-4 md:gap-4 lg:gap-11">
                  <div className="text-2xl leading-8 sm:w-1/3 md:w-40 xl:w-auto">
                    <TextAnimation>{t(service.title)}</TextAnimation>
                  </div>
                  <div className="text-xl leading-8 text-gray-500 flex-1">
                    {service.services.map((item) => (
                      <TextAnimation key={`${item}-${t(item)}`}>
                        <Fragment>
                          {t(item)}
                          <br />
                        </Fragment>
                      </TextAnimation>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
