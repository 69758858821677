import { cn } from "@/utils/cn";
import Image from "next/image";
import Link from "next/link";
import ArrowIcon from "@/assets/arrow-yellow.svg";

interface DiscoverCardProps {
  className?: string;
  href: string;
  text: string;
}

export function DiscoverCard({ className, href, text }: DiscoverCardProps) {
  return (
    <Link href={href}>
      <div
        className={cn(
          "p-2 md:p-5 w-full leading-11 text-white bg-violet-600 rounded-xl flex flex-col justify-end aspect-[0.81] h-full",
          className
        )}
      >
        <Image
          alt="Discover"
          loading="lazy"
          width={150}
          height={94}
          src={ArrowIcon}
          className="max-w-full aspect-[1.59] w-28 sm:w-32"
        />
        <div className="pt-2 md:pt-5 pb-1.5">
          {text.split("<br />").map((line, index) => (
            <span key={index}>
              {line}
              {index < text.split("<br />").length - 1 && <br />}
            </span>
          ))}
        </div>
      </div>
    </Link>
  );
}
