"use client";
import React, { useRef, useEffect } from "react";
import { useAnimate } from "@/store/animate";
import { cn } from "@/utils/cn";
import { useTranslations } from "next-intl";

const IntroAnimation: React.FC = () => {
  const isMobile = typeof window !== "undefined" && window.innerWidth < 768;
  const containerRef = useRef<HTMLDivElement>(null);
  const eyeRef = useRef<HTMLDivElement>(null);
  const sparkleRef = useRef<HTMLDivElement>(null);
  const { setIsAnimating } = useAnimate();
  const t = useTranslations("Home");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!containerRef.current || !eyeRef.current || !sparkleRef.current) return;
    setIsAnimating(true);

    const loadGSAP = async () => {
      const { default: gsap } = await import("gsap");
      const { default: ScrollTrigger } = await import("gsap/ScrollTrigger");

      gsap.registerPlugin(ScrollTrigger);

      gsap.set("body", { background: "black" });

      const loadLottie = async () => {
        const lottie = await import("lottie-web/build/player/lottie_light");

        const eyeLottie = lottie.default.loadAnimation({
          container: eyeRef.current!,
          renderer: "svg",
          loop: false,
          autoplay: false,
          animationData: await import("@/assets/eye-animation.json"),
        });
        eyeLottie.setSpeed(2);

        const sparkleLottie = lottie.default.loadAnimation({
          container: sparkleRef.current!,
          renderer: "svg",
          loop: false,
          autoplay: false,
          animationData: await import("@/assets/sparkle-animation.json"),
        });

        const timeline = gsap.timeline({
          scrollTrigger: {
            trigger: containerRef.current,
            start: "top+=200 bottom",
            toggleActions: "play none none none",
          },
        });

        gsap.fromTo(
          containerRef.current,
          { filter: "blur(20px)", opacity: 0 },
          {
            filter: "blur(0px)",
            opacity: 1,
            duration: 1,
            ease: "power3.out",
            willChange: "filter",
          }
        );

        timeline.to({}, { duration: 0.8 });

        timeline.fromTo(
          ".eye-wrapper",
          { width: 0, willChange: "width" },
          {
            width: isMobile ? "4.5rem" : "7rem",
            duration: 0.3,
            ease: "power3.out",
            onComplete: () => {
              eyeLottie.play();
            },
          }
        );

        eyeLottie.addEventListener("complete", () => {
          setTimeout(() => {
            setIsAnimating(false);
          }, 1300);

          timeline.fromTo(
            ".sparkle-wrapper",
            { width: 0, willChange: "width" },
            {
              width: isMobile ? "3rem" : "5rem",
              duration: 0.3,
              ease: "power2.out",
              onComplete: () => {
                if (!isMobile) {
                  gsap.to(".move", {
                    y: "-10vh",
                    duration: 1.6,
                    ease: "power2.out",
                    willChange: "transform",
                  });
                }
                gsap.to("body", {
                  background: "white",
                  duration: 0.3,
                  ease: "power2.out",
                  willChange: "background-color",
                });
                gsap.to(".first", {
                  ease: "power2.out",
                  backgroundColor: "white",
                  duration: 0.3,
                });
                setIsAnimating(false);
                sparkleLottie.play();
              },
              delay: 0.3,
            }
          );
        });

        return () => {
          eyeLottie.destroy();
          sparkleLottie.destroy();
          gsap.to("body", { background: "white", duration: 0.3 });
        };
      };

      loadLottie();
    };

    loadGSAP();
  }, [isMobile, setIsAnimating]);

  // useEffect(() => {
  //   // Calcola l'altezza dell'header
  //   const header = document.querySelector("header");
  //   const headerHeight = header?.offsetHeight || 0; // Altezza dell'header o 0 come fallback

  //   // Applica il margine negativo al containerRef
  //   if (containerRef.current) {
  //     containerRef.current.style.marginTop = `-${headerHeight}px`;
  //   }
  // }, []);

  return (
    <div
      ref={containerRef}
      style={{ willChange: "filter, background-color" }}
      className={cn(
        "item mix-blend-difference opacity-0 text-white font-light px-2 sm:px-4 md:px-11 text-6xl lg:text-7xl xl:text-8xl leading-tight max-md:max-w-full w-full",
        "overflow-hidden flex-col md:items-center flex justify-center p-5 md:p-0"
      )}
    >
      <h1 className="move gap-5 items-center mt-[28vh] md:mt-[34vh] ml-0" style={{ willChange: "margin-top" }}>
        <div className="flex max-md:flex-col md:items-center">
          <div>{t("weTrasform")}</div>
          <div className="flex items-center">
            <div
              className="order-last md:order-none h-18 mt-2 inline-block overflow-hidden w-0 mx-1 md:mx-3 eye-wrapper"
              style={{ willChange: "width" }}
            >
              <div ref={eyeRef} className={"w-18 md:w-28"} />
            </div>
            <div>{t("visions")}</div>
          </div>
        </div>
      </h1>

      <h1 className="move flex max-md:flex-col md:items-center md:text-center md:mt-7">
        <div>{t("intoImpactful")}</div>

        <div className="flex items-center">
          <div
            className={cn(
              "order-last md:order-none",
              "h-18 inline-block overflow-hidden w-0 mx-1 md:mx-3 sparkle-wrapper"
            )}
            style={{ willChange: "width" }}
          >
            <div ref={sparkleRef} className={"w-12 md:w-20"} />
          </div>
          <div>{t("experiences")}</div>
        </div>
      </h1>
    </div>
  );
};

export default IntroAnimation;
