"use client";
import { useRef, ReactNode } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import SplitType from "split-type";
import { cn } from "@/utils/cn";
import { useGSAP } from "@gsap/react";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

type TextAnimationProps = {
  children: ReactNode | ReactNode[];
  className?: string;
};

const TextAnimation: React.FC<TextAnimationProps> = ({ children, className }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useGSAP(
    () => {
      if (!containerRef.current) return;

      const split = new SplitType(containerRef.current, { types: "words" });

      if (split.words && split.words.length > 0) {
        split.words.forEach((word) => {
          gsap.fromTo(
            word,
            { clipPath: "inset(0% 0% 100% 0%)", y: "100%" },
            {
              clipPath: "inset(0% 0% -6% 0%)",
              y: "-7%",
              ease: "power3.inOut",
              duration: 1,
              scrollTrigger: {
                trigger: word,
                start: "top 100%",
                end: "bottom 0%",
                toggleActions: "play none none none",
              },
              onStart: () => {
                if (containerRef.current) {
                  containerRef.current.classList.add("opacity-100");
                }
              },
            }
          );
        });
      }

      return () => {
        split.revert();
      };
    },
    { scope: containerRef }
  );

  return (
    <div ref={containerRef} className={cn("overflow-hidden opacity-0", className)}>
      {children}
    </div>
  );
};

export default TextAnimation;
