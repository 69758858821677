"use client";
import React, { useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useAnimate } from "@/store/animate";
import { PlayStop } from "./play-stop";
import { useGSAP } from "@gsap/react";

function ShowReelVideo() {
  const boxRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isAnimating } = useAnimate();
  const audioButtonRef = useRef<HTMLButtonElement>(null);
  const [isMuted, setIsMuted] = useState(true);
  const wasAnimating = useRef(false);
  const scrollTriggerInstance = useRef<ScrollTrigger | null>(null);

  useGSAP(() => {
    if (!boxRef.current || !videoRef.current || isAnimating) return;

    const isMobile = window.innerWidth < 768;

    const margin = isMobile ? 0 : 32;
    const vw = window.innerWidth * 0.9 - margin; // Adjusted for margin
    const vh = window.innerHeight / 2; // Adjust for center of the screen
    const aspectRatio = 16 / 9;
    const initialWidth = Math.min(900, vw);
    const initialHeight = initialWidth / aspectRatio;

    if (!isMobile) {
      gsap.set(boxRef.current, {
        width: initialWidth,
        height: initialHeight,
      });
    }

    scrollTriggerInstance.current = ScrollTrigger.create({
      trigger: boxRef.current,
      start: "top bottom",
      end: `top center+=${vh / 2}`, // Adjust end position to stop at screen center
      scrub: true,
      onUpdate: (self) => {
        if (!boxRef.current || !videoRef.current) return;
        if (self.direction === 1) {
          videoRef.current.play();
        }
        const scale = Math.max(0, Math.min(1, self.progress));
        const currentWidth = initialWidth + scale * (vw - initialWidth);
        const currentHeight = currentWidth / aspectRatio;
        const base = 20;
        const currentY = isMobile ? scale * -base : base - scale * base;
        const width = isMobile ? {} : { width: currentWidth, height: currentHeight };

        gsap.to(boxRef.current, {
          y: `${-currentY}vh`,
          ...width,
        });
      },
      onLeave: () => {
        setIsMuted(true);
      },
    });

    return () => {
      scrollTriggerInstance.current?.kill();
    };
  }, [isAnimating]);

  useGSAP(() => {
    if (!audioButtonRef.current || !videoRef.current) return;
    const isMobile = window.innerWidth < 768;
    if (isMobile) return;

    const onMouseMove = (e: MouseEvent) => {
      const rect = videoRef.current?.getBoundingClientRect();
      if (!rect) return;

      const left = e.clientX - rect.left;
      const top = e.clientY - rect.top;

      gsap.to(audioButtonRef.current, {
        left,
        top,
        duration: 0.1,
      });
    };

    videoRef.current.addEventListener("mousemove", onMouseMove);
  }, [audioButtonRef, videoRef]);

  useGSAP(() => {
    if (isAnimating) {
      wasAnimating.current = true;
      return;
    }
    if (!isAnimating && boxRef.current && wasAnimating.current) {
      const isMobile = window.innerWidth < 768;
      const addProps = isMobile ? {} : { delay: 0 };
      const animation = gsap.to(boxRef.current, {
        opacity: 1,
        duration: 1.9,
        y: isMobile ? "0" : "-20vh",
        ease: "power2.out",
        ...addProps,
      });
      videoRef.current?.play();

      return () => {
        animation.kill();
      };
    }
  }, [isAnimating]);

  return (
    <div className="w-full h-[54vh] sm:h-[67vh] md:h-[65vh] lg:h-auto lg:mt-20 xl:mt-0 px-6 md:px-0">
      <div className="flex justify-center md:items-center w-full sm:h-[50vh] md:h-[74vh] lg:h-screen">
        <div
          ref={boxRef}
          className="group opacity-0 translate-y-full rounded-lg overflow-hidden m-4 mx-auto transition-all duration-300 ease-out cursor-none relative flex justify-center"
          onClick={() => {
            setIsMuted((prev) => !prev);
          }}
        >
          <video
            ref={videoRef}
            className="object-contain md:object-cover rounded-lg"
            loop
            playsInline
            muted={isMuted}
            autoPlay={false}
          >
            <source src="/showreel.mp4" type="video/mp4" />
            <div className="animate-pulse bg-light-gray absolute -z-10 size-full top-0 left-0 rounded-lg"></div>
          </video>
          <button
            className="invisible md:visible group-hover:opacity-100 duration-150 opacity-0 absolute top-0 left-0 z-10 background-red-500 origin-center -translate-x-1/2 -translate-y-1/2 cursor-none pointer-events-none"
            ref={audioButtonRef}
          >
            <PlayStop isMuted={isMuted} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ShowReelVideo;
