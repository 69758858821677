"use client";

import React from "react";
import dynamic from "next/dynamic";
import { useTranslations } from "next-intl";
import TextAnimation from "./animations/text-animation";

function TextSectionHome() {
  const t = useTranslations("Home");

  const textAnimationKey = `${t("weAreAnAgencyOf")}-${t(" digitalNatives ")}-${t("costantMotion")}-${t(
    " tailoredSolutions "
  )}-${t("consultingServices")}`;

  return (
    <div className="my-7 md:my-0 lg:my-11 text-2xl md:text-center max-md:max-w-full flex justify-center items-center">
      <div className="flex p-4 w-full justify-center">
        <div className="md:w-680 lg:w-5/6 xl:w-700">
          <TextAnimation key={textAnimationKey}>
            {t("weAreAnAgencyOf")}
            <span className="text-violet-600 font-medium mx-1">{t(" digitalNatives ")} </span>
            {t("costantMotion")}
            <span className="text-violet-600 font-medium mx-1">{t(" tailoredSolutions ")}</span>
            {t("consultingServices")}
          </TextAnimation>
        </div>
      </div>
    </div>
  );
}

export default TextSectionHome;
